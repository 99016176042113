<template>
    <div>
        <pvCard>
            <template #content>
                <h3>You are now Logged out</h3>
                <br><br>
                <pvButton label="Login" v-on:click="gotoLogin"></pvButton>
            </template>
        </pvCard>
    </div>
</template>

<script>
export default {
    data() {
        return {
            userName: ''
        }
    },
    watch: {

    },
    computed: {
        isLoggedIn() {
            return this.$store.getters["isAuthenticated"];
        }

    },
    methods: {
        gotoLogin() {
            this.$router.push("login");
        },
    },


}
</script>