<template>
    <div>
        <pvCard style="width: 1000px; margin-left: auto; margin-right: auto;">
            <template #content>
                <h3>Synar Scorecard</h3>
                <br>
                <pvDataTable :value="data" :loading="loading" ref="dt" class="p-datatable-sm" showGridlines>
                    <template #empty> No found. </template>
                    <template #loading> Loading data. Please wait. </template>
                    <template #header>
                        <div>
                            <pvDropdown v-model="period" :options="yearList" optionLabel="YEAR_TEXT" optionValue="YEAR_TEXT" v-on:change="getReportData" class="dm"></pvDropdown>
                            <pvButton icon="pi pi-external-link" label="Export" v-on:click="gotoExport($event)" style="margin-left: 10px;"></pvButton> 
                        </div>    
                    </template>    
                    <pvColumn header="Detail" class="dtCol">
                        <template #body="slotProps">
                            <pvButton icon="pi pi-folder-open" outlined rounded class="mr-2" v-on:click="showDetail(slotProps.data)"></pvButton>
                        </template>
                    </pvColumn>
                    <pvColumn field="CNTY_ABBR" header="COUNTY" class="dtCol" style="font-size: 12px;"></pvColumn>
                    <pvColumn field="sample_size" header="SAMPLE SIZE" class="dtCol" style="font-size: 12px;"></pvColumn>
                    <pvColumn field="buys_completed" header="CONTROLLED BUYS COMPLETED" class="dtCol" style="font-size: 12px;"></pvColumn>
                    <pvColumn field="not_attempted" header="CONTROLLED BUYS NOT ATTEMPTED" class="dtCol" style="font-size: 12px;"></pvColumn>
                    <pvColumn field="Calculated" header="COMPLIANCE RATE" class="dtCol" style="font-size: 12px;"></pvColumn>
                    <pvColumn field="female_ratio" header="FEMALE GENDER RATIO" class="dtCol" style="font-size: 12px;"></pvColumn>
                    <pvColumn field="male_ratio" header="MALE GENDER RATIO" class="dtCol" style="font-size: 12px;"></pvColumn>
                </pvDataTable>
            </template>
        </pvCard>
        <pvDialog v-model:visible="detailDialog" modal header="SYNAR Detail" style="width: 1000px;">
            <pvCard style="width: 950px;">
                <template #content>
                    <pvDataTable :value="dataDetail" scrollable :loading="dLoading" 
                            scrollHeight="300px" ref="dt" class="p-datatable-sm" showGridlines >
                        <template #empty> No data found. </template>
                        <template #loading> Loading data. Please wait. </template>
                        <template #header><pvButton icon="pi pi-external-link" label="Export" v-on:click="gotoExportDetail($event)" style="margin-left: 10px;"></pvButton> </template>
                        <pvColumn field="INSPECTION_ID" header="INSPECTION_ID" style="font-size: 12px;"/>
                        <pvColumn field="AGENT_ID" header="AGENT_ID" style="font-size: 12px;"/>
                        <pvColumn field="UCI_GENDER" header="UCI_GENDER" style="font-size: 12px;"/>
                        <pvColumn field="LICENSE_NO" header="LICENSE_NO" style="font-size: 12px;"/>
                        <pvColumn field="PERMIT_NO" header="PERMIT_NO" style="font-size: 12px;"/>
                        <pvColumn field="STORE_NAME" header="STORE_NAME" style="font-size: 12px;"/>
                        <pvColumn field="DBA_NAME" header="DBA_NAME" style="font-size: 12px;"/>
                        <pvColumn field="STORE_ADDRESS" header="STORE_ADDRESS" style="font-size: 12px;"/>
                        <pvColumn field="STORE_CITY" header="STORE_CITY" style="font-size: 12px;"/>
                        <pvColumn field="STORE_ZIP" header="STORE_ZIP" style="font-size: 12px;"/>
                        <pvColumn field="STORE_COUNTY" header="STORE_COUNTY" style="font-size: 12px;"/>
                        <pvColumn field="STORE_STRATA" header="STORE_STRATA" style="font-size: 12px;"/>
                        <pvColumn field="REASONS_TXT" header="REASONS_TXT" style="font-size: 12px;"/>
                        <pvColumn field="REASON_DETAIL" header="REASON_DETAIL" style="font-size: 12px;"/>
                        <pvColumn field="TOBACCO_BRAND" header="TOBACCO_BRAND" style="font-size: 12px;"/>
                        <pvColumn field="CLERK_APPROXAGE" header="CLERK_APPROXAGE" style="font-size: 12px;"/>
                        <pvColumn field="CLERK_DOB" header="CLERK_DOB" style="font-size: 12px;"/>
                        <pvColumn field="CLERK_GENDER" header="CLERK_GENDER" style="font-size: 12px;"/>
                        <pvColumn field="ID_ASKED" header="ID_ASKED" style="font-size: 12px;"/>
                        <pvColumn field="UCI_AGE_ASKED" header="UCI_AGE_ASKED" style="font-size: 12px;"/>
                        <pvColumn field="VIOLATED" header="VIOLATED" style="font-size: 12px;"/>
                    </pvDataTable>
                </template>
            </pvCard>
            <template #footer>
                <pvButton label="Close" v-on:click="closeDetail"></pvButton>
            </template>
        </pvDialog>
        <pvDialog v-model:visible="errorDialog" modal header="Error Notification" style="width: 400px;">
            <pvCard style="width: 350px;">
                <template #content>
                    <h4>{{ errorText }}</h4>
                </template>
            </pvCard>
            <template #footer>
                <pvButton label="Close" v-on:click="closeError"></pvButton>
            </template>
        </pvDialog>
    </div>
</template>

<script>
import Papa from "papaparse";
export default {
    data() {
        return {
            data: [],
            dataDetail: [],
            yearList: [],
            loading: false,
            dLoading: false,
            period: '',
            detailDialog: false,
            errorDialog: false,
            errorText: '',
        }
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters["isAuthenticated"];
        }
    },
    methods: {
        closeError() {
            this.errorDialog = false;
        },
        closeDetail() {
            this.detailDialog = false;
        },
        gotoExport() {
            //this.$refs.dt.exportCSV();

            var blob = new Blob([Papa.unparse(this.data)], { type: 'text/csv;charset=utf-8;' });

            var link = document.createElement("a");

            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", 'synar_scorecard.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        gotoExportDetail() {
            //this.$refs.dt.exportCSV();

            var blob = new Blob([Papa.unparse(this.dataDetail)], { type: 'text/csv;charset=utf-8;' });

            var link = document.createElement("a");

            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", 'synar_scoreboard_detail.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        async getReportData() {
            if(this.isLoggedIn === false){
                return;
            }
            try {
                const formData = {
                    period: this.period
                }
                this.loading = true;
                await this.$store.dispatch('getSynarScorecard', formData);
                this.data = this.$store.getters['getSynarScorecard'];
                this.loading = false;
                //console.log(this.data);

            } catch (error) {
                console.log (this.error = error.message  + " Inside script" || "Something failed!");
                this.dLoading = false;
                this.errorText ='Unable to connect to the database','Connection Error';
                this.errorDialog = true;
            }
            
        },
        async showDetail(data) {
            //console.log(data);
            
            try {
                this.detailDialog = true;
                const formData = {
                    period: this.period,
                    county: data.CNTY_ABBR
                }
                this.dLoading = true;
                await this.$store.dispatch('getSynarScorecardDetail', formData);
                this.dataDetail = this.$store.getters['getSynarScorecardDetail'];
                this.dLoading = false;
                //console.log(this.dataDetail);
            } catch (error) {
                console.log (this.error = error.message  + " Inside script" || "Something failed!");
                this.dLoading = false;
                this.errorText ='Unable to connect to the database','Connection Error';
                this.errorDialog = true;
                }
        },
        getYearList() {
            this.yearList = this.$store.getters['getYearList'];
            this.period = this.yearList[0].YEAR_TEXT;

            //console.log(this.yearList);
        }
    },
    mounted() {
        this.getYearList();
        this.getReportData();
    }
}
</script>

<style scoped>
    .dm{
        width: 400px;
    }
</style>