import { createRouter, createWebHashHistory } from 'vue-router'

import home from '../views/HomeView.vue';
import login from '../views/system/sysLogin.vue';
import logout from '../views/system/sysLogout.vue';
import about from '../views/AboutView.vue';
import notFound from '../views/system/notFound.vue';
import report1 from '../views/reports/rptReport1.vue';
import report2 from '../views/reports/rptReport2.vue';
import report3 from '../views/reports/rptReport3.vue';
import report4 from '../views/reports/rptReport4.vue';
import userList from '../views/system/userList.vue';
import uploadSample from '../views/files/sampleUpload.vue';
import assignments from '../views/assignments/menuAssignments.vue';
import missingAssignments from '../views/assignments/missingAssignments.vue';




const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {name: 'root', path: '/', redirect: '/home'},
        {name: 'home', path: '/home', component: home},
        {name: 'login', path: '/login', component: login},
        {name: 'logout', path: '/logout', component: logout},
        {name: 'about', path: '/about', component: about},
        {name: 'report1', path: '/report1', component: report1},
        {name: 'report2', path: '/report2', component: report2},
        {name: 'report3', path: '/report3', component: report3},
        {name: 'report4', path: '/report4', component: report4},
        {name: 'userList', path: '/userList', component: userList},
        {name: 'uploadSample', path: '/uploadSample', component: uploadSample},
        {name: 'assignments', path: '/assignments', component: assignments},
        {name: 'missingAssignments', path: '/missingAssignments', component: missingAssignments},
        {name: 'notFound', path: '/:notFound(.*)', component: notFound}
    ],
})

export default router
