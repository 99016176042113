import { createApp } from 'vue'
import App from './App.vue'

import router from './router/index.js'
import store from './store'

import PrimeVue from 'primevue/config';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'primevue/resources/themes/saga-green/theme.css';

import Menubar from 'primevue/menubar';
import ToolBar from 'primevue/toolbar';
import Button from 'primevue/button';
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import ToggleButton from 'primevue/togglebutton';
import Password from 'primevue/password';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Menu from 'primevue/menu';
import FileUpload from 'primevue/fileupload';
import TextArea from 'primevue/textarea';
import Dialog from 'primevue/dialog';
import DynamicDialog from 'primevue/dynamicdialog';
import AutoComplete from 'primevue/autocomplete';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import Fieldset from 'primevue/fieldset';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Tooltip from 'primevue/tooltip';
import ConfirmPopup from 'primevue/confirmpopup';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import Toast from 'primevue/toast';
import Editor from 'primevue/editor';
import DialogService from 'primevue/dialogservice';
import InputMask from 'primevue/inputmask';
import DatePicker from 'primevue/calendar';
import InputSwitch from 'primevue/inputswitch';
import Row from 'primevue/row';
import SelectButton from 'primevue/selectbutton';

import Footer from './views/ui/vue-footer.vue';
import Header from './views/ui/vue-header.vue';

import VueCryptojs from 'vue-cryptojs'

const app = createApp(App);

app.use(PrimeVue);
app.use(store);
app.use(router);
app.use(ToastService);
app.use(ConfirmationService);
app.use(DialogService);

app.use(VueCryptojs);

app.component('pvMenubar', Menubar);
app.component('pvToolBar', ToolBar);
app.component('pvButton', Button);
app.component('pvCard', Card);
app.component('pvInputText', InputText);
app.component('pvPassword', Password);
app.component('pvDataTable', DataTable);
app.component('pvColumn', Column);
app.component('pvColumnGroup', ColumnGroup);
app.component('pvMenu', Menu);
app.component('pvFileUpload', FileUpload);
app.component('pvTextArea',TextArea);
app.component('pvDialog', Dialog);
app.component('pvDynamicDialog', DynamicDialog );
app.component('pvInputNumber', InputNumber);
app.component('pvToggleButton', ToggleButton);
app.component('pvAutoComplete', AutoComplete);
app.component('pvDropdown', Dropdown);
app.component('pvCalendar', Calendar);
app.component('pvFieldset', Fieldset);
app.component('pvAccordion', Accordion);
app.component('pvAccordionTab', AccordionTab);
app.component('pvConfirmPopup', ConfirmPopup);
app.component('pvToast', Toast);
app.directive('pvTooltip', Tooltip);
app.component('pvEditor', Editor);
app.component('pvInputMask', InputMask);
app.component('pvDatePicker', DatePicker);
app.component('pvInputSwitch', InputSwitch);
app.component('pvRow', Row);
app.component('pvSelectButton', SelectButton);


app.component('vue-header', Header);
app.component('vue-Footer', Footer);

app.mount('#app')
