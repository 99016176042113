<template>
    <div>
        <pvCard>
            <template #content>
                <h2>Inspection Assignments</h2>
                <h3>Missing {{missing}} Inspection Assignments</h3>
                <pvDataTable
                    :value="assignmentList"
                    :loading="isLoading"
                    ref="dt"
                    sortMode="multiple"
                    :paginator="true" :rows="20"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :rowsPerPageOptions="[10,20,50]"
                    paginatorPosition="top"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    class="p-datatable-sm">
                    <template #empty>
                        No Data Found
                    </template>
                    <template #loading>
                        Loading Data, Please Wait
                    </template>
                    <template #header>
                        <div>
                            <label>Select Reporting Period: </label>
                            <pvDropdown v-model="period" :options="yearList" optionLabel="YEAR_TEXT" optionValue="YEAR_TEXT" v-on:change="getReportData" style="width: 350px" class="dm"></pvDropdown>
                            <label> Select By: </label>
                            <pvDropdown v-model="type" :options="typeOption" optionLabel="name" optionValue="name" v-on:change="getAssignmentsList" style="width: 150px" class="dm"></pvDropdown>
                        </div>
                    </template>
                    <pvColumn field="LICENSE_ID" header="License Num" sortable style="width: 150px; font-size: 12px;"></pvColumn>
                    <pvColumn field="PERMIT_NO" header="Permit Num" sortable style="width: 150px; font-size: 12px;"></pvColumn>
                    <pvColumn field="STORE_NAME" header="Store Name" sortable style="width: 400px; font-size: 12px;"></pvColumn>
                    <pvColumn field="STORE_ADDRESS" header="Address" sortable style="width: 200px; font-size: 12px;"></pvColumn>
                    <pvColumn field="STORE_CITY" header="City" sortable style="width: 200px; font-size: 12px;"></pvColumn>
                    <pvColumn field="STORE_COUNTY" header="County" sortable style="width: 200px; font-size: 12px;"></pvColumn>
                    <pvColumn field="aName" header="Agent" sortable style="width: 200px; font-size: 12px;"></pvColumn>
                    <pvColumn header="Edit User" class="dtCol" style="width: 50px; font-size: 12px;">
                        <template #body="slotProps">
                            <pvButton icon="pi pi-pencil" outlined rounded class="mr-2" v-on:click="editAssignment(slotProps.data)" size="small"></pvButton>
                        </template>
                    </pvColumn>
                </pvDataTable>
            </template>
        </pvCard>
        <pvDialog v-model:visible="errorDialog" modal header="Error Notification" style="width: 400px;">
            <pvCard style="width: 350px;">
                <template #content>
                    <h4>{{ errorText }}</h4>
                </template>
            </pvCard>
            <template #footer>
                <pvButton label="Close" v-on:click="closeError"></pvButton>
            </template>
        </pvDialog>
        <pvDialog v-model:visible="editDialog" modal header="Edit Assignments" style="width: 500px;">
            <pvCard style="width: 450px">
                <template #content>
                    <table style="width: 100%;">
                        <tr>
                            <td>License Number: </td>
                            <td>{{eAssignLic}}</td>
                        </tr>
                        <tr>
                            <td>Permit Number: </td>
                            <td>{{eAssignPer}}</td>
                        </tr>
                        <tr>
                            <td>Store Name: </td>
                            <td>{{eAssignName}}</td>
                        </tr>
                        <tr>
                            <td>City: </td>
                            <td>{{eAssignCity}}</td>
                        </tr>
                        <tr>
                            <td>County: </td>
                            <td>{{eAssignCounty}}</td>
                        </tr>
                        <tr>
                            <td>Assigned To:</td>
                            <td><pvDropdown v-model="eAssignTo" :options="assignList" optionLabel="fName" optionValue="fName" v-on:change="changeAssignment" style="width: 250px" class="dm"></pvDropdown></td>
                        </tr>
                    </table>
                    <pvButton icon="pi pi-save" label="Save" outlined rounded class="mr-2" v-on:click="saveAssignment" size="small"></pvButton>
                    <pvButton icon="pi pi-times" label="Close" outlined rounded class="mr-2" v-on:click="closeAssignment" size="small"></pvButton>
                </template>
            </pvCard>
        </pvDialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            pageName: 'Assignments',
            pageAccess: null,
            readOnly: true,
            userAccess: null,
            isLoading: false,
            assignmentList: [],
            period: '',
            periodID: '',
            selectedPeriod: '',
            yearList: [],
            type: 'ALL',
            errorText: '',
            errorDialog: false,
            typeOption: [
                {name: 'ALL'},
                {name: 'MISSING'}
            ],
            missing: 0,
            editDialog: false,
            assignList: [],
            eAssignID: '',
            eAssignLic: '',
            eAssignPer: '',
            eAssignName: '',
            eAssignCity: '',
            eAssignCounty: '',
            eAssignTo: '',
            agentID: '',
        }
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters["isAuthenticated"];
        },
    },
    methods: {
        closeError() {
            this.errorDialog = false;
        },
        async editAssignment(data) {
            console.log(data);
            
            try {
                this.isLoading = true;
                await this.$store.dispatch('pullAssignmentsListCounty', data.STORE_COUNTY);
                this.assignList = this.$store.getters['getAssignmentsListCounty'];
                this.isLoading = false;
                console.log(this.assignList);
                //console.log(JSON.stringify(this.licenseList));
            } catch(error) {
                console.log (this.error = error.message  + " Inside script" || "Something failed!");
                this.isLoading = false;
                this.errorText ='Unable to connect to the database','Connection Error';
                this.errorDialog = true;
                return;
            }

            this.eAssignID = data.ASSIGN_ID
            this.eAssignLic = data.LICENSE_ID
            this.eAssignPer = data.PERMIT_NO
            this.eAssignName = data.STORE_NAME
            this.eAssignCity = data.STORE_CITY
            this.eAssignCounty = data.STORE_COUNTY
            this.eAssignTo = data.aName
            this.editDialog = true;

        },
        closeAssignment() {
            this.editDialog = false;
        },
        changeAssignment() {
            console.log(this.eAssignTo);
            let agentIndex = this.assignList.map(e => e.fName).indexOf(this.eAssignTo);
            console.log(agentIndex);
            this.agentID = this.assignList[agentIndex].AGENT_ID;
            console.log(this.agentID);
        },
        async saveAssignment() {
            if(this.eAssignTo === '' || this.eAssignTo === null) {
                return;
            }

            var response = '';
            const formData = {
                assignID: this.eAssignID,
                agentID: this.agentID
            }
            
            console.log(formData);
            console.log('SAVED 1');
            try {
                console.log('SAVED 2');
                await this.$store.dispatch('putAssignmentsSave', formData);
                response = this.$store.getters['getAssignmentSaveResponse'];
                console.log(response);

                await new Promise(resolve => setTimeout(resolve, 1000));

                this.editDialog = false;
                this.getAssignmentsList();

            } catch(error) {
                console.log (this.error = error.message  + " Inside script" || "Something failed!");
                this.isLoading = false;
                this.errorText ='Unable to connect to the database','Connection Error';
                this.errorDialog = true;
            }
        },
        async getAssignmentsList() {
            if(this.isLoggedIn === false) {
                return;
            }
            const formData = {
                year: this.selectedPeriod,
                type: this.type
            }

            try {
                this.isLoading = true;
                await this.$store.dispatch('pullAssignmentsList', formData);
                this.assignmentList = this.$store.getters['getAssignmentsList'];
                this.isLoading = false;

                console.log(this.assignmentList.length);

                if(this.assignmentList.length === 0) {
                    this.missing = 0;
                } else {
                    this.missing = this.assignmentList[0].missingAgent;
                }
                console.log(this.assignmentList);

            } catch(error) {
                console.log (this.error = error.message  + " Inside script" || "Something failed!");
                this.isLoading = false;
                this.errorText ='Unable to connect to the database','Connection Error';
                this.errorDialog = true;
            }

        },
        async getReportData() {
            if(this.isLoggedIn === false){
                return;
            }

            this.periodID = this.yearList.map(e => e.YEAR_TEXT).indexOf(this.period);
            console.log(this.yearList);
            console.log(this.period);
            console.log(this.periodID);
            this.selectedPeriod = this.yearList[this.periodID].YEAR_ID;
            console.log(this.selectedPeriod);
            this.getAssignmentsList();

        },
        getYearList() {
            this.yearList = this.$store.getters['getYearList'];
            this.period = this.yearList[0].YEAR_TEXT;
            this.periodID = this.yearList.map(e => e.YEAR_TEXT).indexOf(this.period);
            this.selectedPeriod = this.yearList[this.periodID].YEAR_ID;
            console.log(this.yearList);
            console.log(this.period);
            console.log(this.periodID);
            console.log(this.selectedPeriod);
            this.getAssignmentsList();

        }
    },
    mounted() {
        this.getYearList();
        this.getAssignmentsList();
    }
}

</script>