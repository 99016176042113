<template>
    <div>
        <table style="width: 100%">
            <tr>
                <th style="width: 50px">
                    <table style="width: 50px">
                        <tr>
                            <th><img alt="Vue logo" src="../../assets/Logo.gif"></th>
                        </tr>
                    </table>
                </th>
                <th class="header">
                    <div>
                    <table style="width: 100%">
                        <tr>
                            <th>
                                <h1 style="color: white">ABC Controlled Buy Website</h1>
                            </th>
                        </tr>
                        <tr>
                            <th>
                                <div v-if="isLoggedIn">
                                    <pvButton label="Reports" v-on:click="gotoReports"/>
                                    <pvButton style="margin-left: 10px;" label="Assignments" v-on:click="gotoAssign"/>
                                    <pvButton style="margin-left: 10px;" label="Sample Upload" v-on:click="gotoUpload"/>
                                    <pvButton style="margin-left: 10px;" label="User Management" v-on:click="gotoUsers"/>
                                    <pvButton style="margin-left: 10px;" label="Logout" v-on:click="gotoLogout"/>
                                </div>
                            </th>
                        </tr>
                    </table>
                    </div>
                </th>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            userName: ''
        }
    },
    watch: {

    },
    computed: {
        isLoggedIn() {
            return this.$store.getters["isAuthenticated"];
        }

    },
    methods: {
        gotoReports() {
            this.$router.push("home");
        },
        gotoLogout() {
            this.$store.dispatch('logout');
            this.$router.push("logout");
        },
        gotoUsers() {
            this.$router.push("userList");
        },
        gotoUpload() {
            this.$router.push("uploadSample");
        },
        gotoAssign() {
            this.$router.push("assignments");
        }

    },
    mounted() {
        if(!this.isLoggedIn) {
            this.$router.push("login");
        }
    }

}
</script>

<style>

    .header {
        background-color: #003366;
    }
</style>