<template>
    <div>
        <pvCard v-if="!fileSelected">
            <template #content>
                <h2>Synar Sample Upload</h2>
                <table style="margin-left: auto; margin-right: auto;">
                    <tr>
                        <td><h5>Select Calendar Year</h5></td>
                        <td><h5>Select a Sample File to Upload</h5></td>
                    </tr>
                    <tr>
                        <td><pvDropdown v-model="period" :options="yearList" optionLabel="YEAR_TEXT" optionValue="YEAR_TEXT" v-on:change="getReportData" style="width: 300px" class="dm"></pvDropdown></td>
                        <td><input type="file" id="sampleFile" @change="onFileChange"/></td>
                    </tr>
                </table>
                <p>File being uploaded must be a valid Excel File (xlsx), and contain 2 fields with the Headers of LICENSE_NO and PERMIT_NO</p>
            </template>
        </pvCard>
        <br>
        <pvCard v-if="!fileSelected">
            <template #content>
                <h3>List of Current Licenses with Permits</h3>
                <p>Use this List to create your sample file.</p>
                <pvDataTable 
                    :value="licenseList"
                    :loading="isLoading"
                    ref="dt"
                    sortMode="multiple"
                    :paginator="true" :rows="20"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :rowsPerPageOptions="[10,20,50]"
                    paginatorPosition="top"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    class="p-datatable-sm">
                    <template #empty>
                        No Data Found
                    </template>
                    <template #loading>
                        Loading Data, Please Wait
                    </template>
                    <template #header>
                        <div>
                            <pvButton icon="pi pi-external-link" label="Export License List to CSV" v-on:click="gotoExport($event)" style="margin-left: 10px;"></pvButton> 
                        </div>
                    </template>
                    <pvColumn field="LICENSE_NO" header="License Num" sortable style="width: 100px; font-size: 12px;"></pvColumn>
                    <pvColumn field="PERMIT_NO" header="Permit Num" sortable style="width: 100px; font-size: 12px;"></pvColumn>
                    <pvColumn field="STORE_NAME" header="Store Name" sortable style="width: 400px; font-size: 12px;"></pvColumn>
                    <pvColumn field="STORE_ADDRESS" header="Address" sortable style="width: 200px; font-size: 12px;"></pvColumn>
                    <pvColumn field="STORE_CITY" header="City" sortable style="width: 200px; font-size: 12px;"></pvColumn>
                    <pvColumn field="STORE_COUNTY" header="County" sortable style="width: 200px; font-size: 12px;"></pvColumn>
                    <pvColumn field="STORE_STRATA" header="Strata" sortable style="width: 200px; font-size: 12px;"></pvColumn>
                    <pvColumn field="RENEWAL_DATE" header="Renewal Date" style="width: 200px; font-size: 12px"></pvColumn>
                </pvDataTable>
                <br>
            </template>
        </pvCard>
        <pvCard v-if="fileSelected">
            <template #content>
                <h3>Please Verify this is the data you want to upload.</h3>
                <pvDataTable 
                    :value="rowDataShow"
                    :loading="isLoading"
                    ref="dt"
                    sortMode="multiple"
                    :paginator="true" :rows="20"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :rowsPerPageOptions="[10,20,50]"
                    paginatorPosition="top"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    class="p-datatable-sm">
                    <template #empty>
                        No Data Found
                    </template>
                    <template #loading>
                        Loading Data, Please Wait
                    </template>
                    <template #header>
                        <div>
                            <pvButton icon="pi pi-external-link" label="Clear Import Data" v-on:click="gotoClear($event)" style="margin-left: 10px;"></pvButton> 
                            <pvButton icon="pi pi-external-link" label="Import Data" v-on:click="gotoImport($event)" style="margin-left: 10px;"></pvButton> 
                        </div>
                    </template>
                    <pvColumn field="LICENSE_NO" header="License Num" sortable style="width: 200px"></pvColumn>
                    <pvColumn field="PERMIT_NO" header="Permit Num" sortable style="width: 200px"></pvColumn>
                    <pvColumn field="INSPECT_YEAR" header="Inspection Year" style="width: 200px"></pvColumn>
                </pvDataTable>
                <br>
            </template>
        </pvCard>
        <pvDialog v-model:visible="errorDialog" modal header="App Message" style="width: 400px;">
            <pvCard style="width: 350px;">
                <template #content>
                    <h4>{{ errorText }}</h4>
                </template>
            </pvCard>
            <template #footer>
                <pvButton label="Close" v-on:click="closeError"></pvButton>
            </template>
        </pvDialog>
    </div>
</template>

<script>
import readXlsxFile from 'read-excel-file';
import Papa from "papaparse";
export default {
    data() {
        return {
            pageName: 'SAMPLE UPLOAD',
            pageAccess: null,
            readOnly: true,
            userAccess: null,
            dataArray: null,
            rowData: null,
            rowDataShow: null,
            isLoading: false,
            licenseList: null,
            errorDialog: false,
            fileSelected: false,
            yearList: [],
            period: '',
            periodID: '',
            selectedPeriod: '',
            selectedYear: '',
            fileName: '',
            mSample: '',
            title: '',
        }
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters["isAuthenticated"];
        },
    },
    methods: {
        closeError() {
            this.errorDialog = false;
        },
        async onFileChange(event) {
            const xlsxScheme = this.schema;

            this.fileName = event.target.files[0].name
            console.log(this.fileName);
            console.log(this.fileName.length - 4);
            console.log(this.fileName.substring(this.fileName.length - 4));
            if(this.fileName.substring(this.fileName.length - 4) != 'xlsx') {
                //console.log(this.fileName.substring(this.fileName.length - 4));
                this.isLoading = false;
                this.title = "Error"
                this.errorText ='The file you tried to upload is not the correct file format.  Must be a valid xlsx (excel) file.';
                this.errorDialog = true; 
                this.fileSelected = false;
                return;
            }

            let xlsxFile = event.target.files ? event.target.files[0] : null;
            
            readXlsxFile(xlsxFile, {xlsxScheme}).then((rows) => {
                    try {
                    this.rowData = rows;
                    let headerData = this.rowData[0];
                    let ir = 1;
                    let id = 0;
                    let maxr = this.rowData.length;
                    let maxd = null;
                    let tempRow = '';
                    //let tempRowShow = '';
                    let tempData = '';
                    //let tempDataShow = '';
                    let jsonData = '[';
                    let jsonDataShow = '[';
                    
                    console.log(JSON.stringify(headerData));
                    if(JSON.stringify(headerData) != '["LICENSE_NO","PERMIT_NO"]') {
                        console.log ("File is not the correct format");
                        this.isLoading = false;
                        this.title = "Error"
                        this.errorText ='1 The file you tried to upload is not correct.  Must be in excel with 2 columns.  LICENSE_NO and PERMIT_NO';
                        this.errorDialog = true; 
                        this.fileSelected = false;
                        return;
                    }

                    while (ir < maxr) {
                        tempRow = this.rowData[ir];
                        //tempRowShow = this.rowData[ir];
                        maxd = tempRow.length;
                        jsonData = jsonData + '{'
                        while (id < maxd) {

                            tempData = tempData + '"' + headerData[id] + '":"' + tempRow[id] + '",';
                            //tempDataShow = tempDataShow + '"' + headerData[id] + '":"' + tempRowShow[id] + '",';

                            id++;
                        }
                        jsonData = jsonData + tempData + '"INSPECT_YEAR":"' + this.selectedPeriod + '"'
                        //jsonDataShow = jsonDataShow + tempDataShow + '"INSPECT_YEAR":"' + this.selectedYear + '"'
                        tempData = '},';
                        //tempDataShow = '},';

                        id = 0;
                        ir++;
                    }
                    //console.log(jsonData);
                    jsonData = jsonData + ']';
                    jsonData = jsonData.replaceAll("{},", "},{");
                    jsonData = jsonData.replaceAll("]", "}]");

                    jsonDataShow = jsonData
                    jsonDataShow = jsonDataShow.replaceAll(this.selectedPeriod, this.selectedYear);

                    //console.log(jsonData);
                    this.rowData = JSON.parse(jsonData);
                    this.rowDataShow = JSON.parse(jsonDataShow);
                    //console.log("PT1");
                    //console.log(this.rowData);
                    //console.log(this.rowDataShow);

                    if(this.rowDate != null) {this.fileSelected = true;} else {this.fileSelected = true;}
                } catch (error) {
                    console.log (this.error = error.message  + "File is not the correct format");
                    this.isLoading = false;
                    this.title = "Error"
                    this.errorText ='2 The file you tried to upload is not correct.  Must be in excel with 2 columns.  LICENSE_NO and PERMIT_NO';
                    this.errorDialog = true; 
                    this.fileSelected = false;
                }
            })
            
        },
        async getLicenseList() {
            if(this.isLoggedIn === false){
                return;
            }
            try {
                this.isLoading = true;
                await this.$store.dispatch('getLicensesCurrentActive');
                this.licenseList = this.$store.getters['getActiveLicenses'];
                this.isLoading = false;
                //console.log(this.licenseList);
                //console.log(JSON.stringify(this.licenseList));
            } catch(error) {
                console.log (this.error = error.message  + " Inside script" || "Something failed!");
                this.isLoading = false;
                this.title = "Error"
                this.errorText ='Unable to connect to the database','Connection Error';
                this.errorDialog = true;
            }
        },
        gotoExport() {
            var blob = new Blob([Papa.unparse(this.licenseList)], { type: 'text/csv;charset=utf-8;' });

            var link = document.createElement("a");

            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", 'licenseList.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        gotoClear() {
            this.fileSelected = false;
            //document.getElementById("sampleFile").value = "";
        },
        async gotoImport() {
            try {

                await this.$store.dispatch('putImportSample', JSON.stringify(this.rowData));
                this.mSample = await this.$store.getters["getMSampleData"];
                console.log(this.mSample);
                this.isLoading = false;
                this.title = "Save Successful"
                this.errorText ='The sample data has been saved successfully';
                this.errorDialog = true;
            } catch(error) {
                console.log (this.error = error.message  + " Inside script" || "Something failed!");
                this.isLoading = false;
                this.title = "Error"
                this.errorText ='Unable to connect to the database','Connection Error';
                this.errorDialog = true;
            }
        },
        async getReportData() {
            if(this.isLoggedIn === false){
                return;
            }

            this.periodID = this.yearList.map(e => e.YEAR_TEXT).indexOf(this.period);
            console.log(this.yearList);
            console.log(this.period);
            console.log(this.periodID);
            this.selectedPeriod = this.yearList[this.periodID].YEAR_ID;
            console.log(this.selectedPeriod);

            this.selectedYear = this.yearList[this.periodID].fStop.substring(this.yearList[this.periodID].fStop.length - 4);
            console.log("selected year");
            console.log(this.selectedYear);

        },
        getYearList() {
            this.yearList = this.$store.getters['getYearList'];
            this.period = this.yearList[0].YEAR_TEXT;
            this.periodID = this.yearList.map(e => e.YEAR_TEXT).indexOf(this.period);
            this.selectedPeriod = this.yearList[this.periodID].YEAR_ID;
            this.selectedYear = this.yearList[0].fStop.substring(this.yearList[0].fStop.length - 4);
            console.log(this.yearList);
            console.log(this.period);
            console.log(this.periodID);
            console.log(this.selectedPeriod);
            console.log("selected year");
            console.log(this.selectedYear);
        }
    },
    mounted() {
        this.getYearList();
        this.getLicenseList();
        
    }
}
</script>

<style>
    input {
        width: 300px;
        margin-left: 100px;

    }
    input[type=file]::file-selector-button {
        background-color: #4CAF50;
        color: white;
        border: 1px;
        border-right: 1px solid #e5e5e5;
        padding: 10px 15px;
        margin-right: 20px;
        transition: .5s;
    }
</style>