<template>
    <div>
        <h4>Select from the following Reports Available</h4>
        <pvButton label="Synar Scorecard" v-on:click="gotoReport3" /><br><br>
        <pvButton label="Inspection Tallies Eligible / Ineligible" v-on:click="gotoReport1"/><br><br>
        <pvButton label="Chosen Controlled Buy(CCB) Scorecard" v-on:click="gotoReport2"/><br><br>

    </div>
</template>

<script>
export default {
    data() {
        return {
            userName: '',
            data: [],
        }
    },
    watch: {

    },
    computed: {
        isLoggedIn() {
            return this.$store.getters["isAuthenticated"];
        }

    },
    methods: {
        gotoReport1() {
            this.$router.push("report1");
        },
        gotoReport2() {
            this.$router.push("report2");
        },
        gotoReport3() {
            this.$router.push("report3");
        },
        gotoReport4() {
            this.$router.push("report4");
        },
        async getYearList() {
            if(!this.isLoggedIn) {
                return;
            }

            try {

                await this.$store.dispatch('getYearList');
                this.data = this.$store.getters['getYearList'];
                console.log(this.data);

            } catch (error) {
                console.log (this.error = error.message  + " Inside script" || "Something failed!");
                this.$alert('Unable to connect to the database','Connection Error', {
                    confirmButton: 'OK',
                    callback: action => {
                        this.$message({
                            type: 'info',
                            message: `action: ${ action }`
                        })
                    }
                })
            }
        }
    },
    mounted() {
        if(!this.isLoggedIn) {
            this.$router.push("login");
        }
        this.getYearList();
    }

}
</script>
