<template>
    <div>
        <pvCard style="width: 900px; margin-left: auto; margin-right: auto;">
            <template #content>
                <h3>CATE SCORECARD</h3>
                <br>
                <pvDataTable :value="data" :loading="loading" ref="dt" class="p-datatable-sm" showGridlines >
                    <template #empty> No data found. </template>
                    <template #loading> Loading data. Please wait. </template>
                    <template #header>
                        <div>
                            <table style="width: 100%">
                                <tr style="height: 80px">
                                    <th>
                                        <div class="p-float-label">
                                            <pvDropdown v-model="by"
                                                :options="activeAgents" optionLabel="AGENT_NAME" optionValue="AGENT_NAME" 
                                                v-on:change="getReportData" class="dm" inputId="by">
                                            </pvDropdown>
                                            <label for="by">Conducted By</label>
                                        </div>
                                    </th>
                                    <th style="width: 170px">
                                        <span class="p-float-label">
                                            <pvCalendar v-model="fStart" dateFormat="mm/dd/yy" inputId="StartDate" showIcon style="width: 150px" />
                                            <label for="StartDate">Start Date</label>
                                        </span>
                                    </th>
                                    <th style="width: 170px">
                                        <span class="p-float-label">
                                            <pvCalendar v-model="fStop" dateFormat="mm/dd/yy" inputId="StopDate" showIcon style="width: 150px" />
                                            <label for="StopDate">Stop Date</label>
                                        </span>
                                    </th>
                                    <th style="width: 100px">
                                        <pvButton icon="pi pi-external-link" label="Export" v-on:click="gotoExport($event)" style="margin-left: 10px;"></pvButton> 
                                    </th>
                                </tr>
                            </table>
                        </div>    
                    </template> 
                    <pvColumn header="Detail" class="dtCol">
                        <template #body="slotProps">
                            <pvButton icon="pi pi-folder-open" outlined rounded class="mr-2" v-on:click="showDetail(slotProps.data)"></pvButton>
                        </template>
                    </pvColumn>
                    <pvColumn field="CNTY_ABBR" header="COUNTY" class="dtCol" style="font-size: 12px;"></pvColumn>
                    <pvColumn field="Attempted" header="CCB ATTEMPTED" class="dtCol" style="font-size: 12px;"></pvColumn>
                    <pvColumn field="Ineligible" header="CCB INELIGIBLE" class="dtCol" style="font-size: 12px;"></pvColumn>
                    <pvColumn field="Completed" header="CCB COMPLETED" class="dtCol" style="font-size: 12px;"></pvColumn>
                    <pvColumn field="Violation" header="CCB VIOLATIONS" class="dtCol" style="font-size: 12px;"></pvColumn>
                    <pvColumn field="NoViolation" header="CCB NO VIOLATION" class="dtCol" style="font-size: 12px;"></pvColumn>
                    <pvColumn field="Calculated" header="COMPLIANCE RATE" class="dtCol" style="font-size: 12px;"></pvColumn>
                </pvDataTable>
            </template>
        </pvCard>
        <pvDialog v-model:visible="detailDialog" modal header="CATE Detail" style="width: 1000px;">
            <pvCard style="width: 950px;">
                <template #content>
                    <pvDataTable :value="dataDetail" scrollable :loading="dLoading" 
                            scrollHeight="300px" ref="dt" class="p-datatable-sm" showGridlines >
                        <template #empty> No data found. </template>
                        <template #loading> Loading data. Please wait. </template>
                        <template #header><pvButton icon="pi pi-external-link" label="Export" v-on:click="gotoExportDetail($event)" style="margin-left: 10px;"></pvButton> </template>
                        <pvColumn field="INSPECTION_ID" header="INSPECTION_ID" style="font-size: 12px;"/>
                        <pvColumn field="AGENT_ID" header="AGENT_ID" style="font-size: 12px;"/>
                        <pvColumn field="UCI_GENDER" header="UCI_GENDER" style="font-size: 12px;"/>
                        <pvColumn field="LICENSE_NO" header="LICENSE_NO" style="font-size: 12px;"/>
                        <pvColumn field="PERMIT_NO" header="PERMIT_NO" style="font-size: 12px;"/>
                        <pvColumn field="STORE_NAME" header="STORE_NAME" style="font-size: 12px;"/>
                        <pvColumn field="DBA_NAME" header="DBA_NAME" style="font-size: 12px;"/>
                        <pvColumn field="STORE_ADDRESS" header="STORE_ADDRESS" style="font-size: 12px;"/>
                        <pvColumn field="STORE_CITY" header="STORE_CITY" style="font-size: 12px;"/>
                        <pvColumn field="STORE_ZIP" header="STORE_ZIP" style="font-size: 12px;"/>
                        <pvColumn field="STORE_COUNTY" header="STORE_COUNTY" style="font-size: 12px;"/>
                        <pvColumn field="STORE_STRATA" header="STORE_STRATA" style="font-size: 12px;"/>
                        <pvColumn field="REASONS_TXT" header="REASONS_TXT" style="font-size: 12px;"/>
                        <pvColumn field="REASON_DETAIL" header="REASON_DETAIL" style="font-size: 12px;"/>
                        <pvColumn field="TOBACCO_BRAND" header="TOBACCO_BRAND" style="font-size: 12px;"/>
                        <pvColumn field="CLERK_APPROXAGE" header="CLERK_APPROXAGE" style="font-size: 12px;"/>
                        <pvColumn field="CLERK_DOB" header="CLERK_DOB" style="font-size: 12px;"/>
                        <pvColumn field="CLERK_GENDER" header="CLERK_GENDER" style="font-size: 12px;"/>
                        <pvColumn field="ID_ASKED" header="ID_ASKED" style="font-size: 12px;"/>
                        <pvColumn field="UCI_AGE_ASKED" header="UCI_AGE_ASKED" style="font-size: 12px;"/>
                        <pvColumn field="VIOLATED" header="VIOLATED" style="font-size: 12px;"/>
                    </pvDataTable>
                </template>
            </pvCard>
            <template #footer>
                <pvButton label="Close" v-on:click="closeDetail"></pvButton>
            </template>
        </pvDialog>
        <pvDialog v-model:visible="errorDialog" modal header="Error Notification" style="width: 400px;">
            <pvCard style="width: 350px;">
                <template #content>
                    <h4>{{ errorText }}</h4>
                </template>
            </pvCard>
            <template #footer>
                <pvButton label="Close" v-on:click="closeError"></pvButton>
            </template>
        </pvDialog>
    </div>
</template>

<script>
import Papa from "papaparse";
export default {
    data() {
        return {
            data: [],
            dataDetail: [],
            yearList: [],
            activeAgents: [],
            loading: false,
            dLoading: false, 
            by: '',
            dtStart: '',
            dtStop: '',
            fStart: '',
            fStop: '',
            detailDialog: false,
            errorDialog: false,
            errorText: ''
        }
    },
    watch: {
        fStart(data) {
            //console.log(data.length);
            if(data > 10) {
                this.dtStart = data.toISOString();
                
                if(this.dtStart > this.dtStop) {
                    console.log("bad date");
                } else {
                    console.log("good");
                    this.getReportData();
                }
            }
        },
        fStop(data) {
            if(data > 10) {
                this.dtStop = data.toISOString();

                if(this.dtStart > this.dtStop) {
                    console.log("bad date");
                } else {
                    console.log("good");
                    this.getReportData()
                }
            }
        },
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters["isAuthenticated"];
        }
    },
    methods: {
        closeDetail() {
            this.detailDialog = false;
        },  
        closeError() {
            this.errorDialog = false;
        },
        async showDetail(data) {
            //console.log(data);
            
            try {
                this.detailDialog = true;
                const formData = {
                    by: this.by,
                    dtStart: this.dtStart,
                    dtStop: this.dtStop,
                    county: data.CNTY_ABBR
                }
                this.dLoading = true;
                await this.$store.dispatch('getScorecardDetail', formData);
                this.dataDetail = this.$store.getters['getScorecardDetail'];
                this.dLoading = false;
                //console.log(this.dataDetail);
            } catch (error) {
                console.log (this.error = error.message  + " Inside script" || "Something failed!");
                this.dLoading = false;
                this.errorText ='Unable to connect to the database','Connection Error';
                this.errorDialog = true;
                }
        },
        gotoExportDetail() {
            //this.$refs.dt.exportCSV();

            var blob = new Blob([Papa.unparse(this.dataDetail)], { type: 'text/csv;charset=utf-8;' });

            var link = document.createElement("a");

            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", 'cata_scoreboard_detail.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        gotoExport() {
            //this.$refs.dt.exportCSV();

            var blob = new Blob([Papa.unparse(this.data)], { type: 'text/csv;charset=utf-8;' });

            var link = document.createElement("a");

            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", 'cata_scoreboard.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        }, 
        async getReportData() {
            if(this.isLoggedIn === false){
                return;
            }
            try {
                const formData = {
                    by: this.by,
                    dtStart: this.dtStart,
                    dtStop: this.dtStop,
                }
                this.loading = true;
                await this.$store.dispatch('getScorecard', formData);
                this.data = this.$store.getters['getScorecard'];
                this.loading = false;
                //console.log(this.data);

            } catch (error) {
                    console.log (this.error = error.message  + " Inside script" || "Something failed!");
                    this.isLoading = false;
                    this.errorText ='Unable to connect to the database','Connection Error';
                    this.errorDialog = true;
            }
            
            
        },
        getYearList() {
            this.yearList = this.$store.getters['getYearList'];
            //this.by = this.yearList[0].YEAR_TEXT;

            this.dtStart = new Date();
            this.dtStop = new Date();

            this.fStart = new Date();
            this.fStop = new Date();

            let dtm = this.yearList[0].fStart.slice(0,2);
            let dtd = this.yearList[0].fStart.slice(3,5);
            let dty = this.yearList[0].fStart.slice(6,10);

            let dtmf = this.yearList[0].fStop.slice(0,2);
            let dtdf = this.yearList[0].fStop.slice(3,5);
            let dtyf = this.yearList[0].fStop.slice(6,10);

            this.fStart.setFullYear(dty, dtm-1 , dtd);
            this.fStop.setFullYear(dtyf, dtmf-1 , dtdf);

            //console.log("test start");
            //console.log(dtm);
            //console.log(dtd);
            //console.log(dty);
            //console.log(dtmf);
            //console.log(dtdf);
            //console.log(dtyf);
            //console.log("test end");

            this.dtStart = this.yearList[0].START;
            this.dtStop = this.yearList[0].STOP;
            //this.fStart = this.yearList[0].fStart;
            //this.fStop = this.yearList[0].fStop;
            //console.log(this.dtStart);
            //console.log(this.dtStop);
            //console.log(this.fStart);
            //console.log(this.fStop);
            //console.log(this.yearList);
        },
        async getActiveAgents() {
            if(this.isLoggedIn === false){
                return;
            }
            try {

                //this.loading = true;
                await this.$store.dispatch('getActiveAgents');
                this.activeAgents = this.$store.getters['getActiveAgents'];
                //this.loading = false;
                this.by = this.activeAgents[0].AGENT_NAME;
                //console.log(this.activeAgents);

            } catch (error) {
                console.log (this.error = error.message  + " Inside script" || "Something failed!");
                    this.isLoading = false;
                    this.errorText ='Unable to connect to the database','Connection Error';
                    this.errorDialog = true;
            }
        }

    },
    mounted() {
        this.getActiveAgents();
        this.getYearList();
        this.getReportData();
    }
}
</script>

<style scoped>
    .dm{
        width: 320px;
    }
    dtCol {
        width: 100px;
        font-size: 12px;
    }
</style>