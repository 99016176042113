<template>
    <div>
        <pvCard v-if="!isAdmin">
            <template #content>
                <h4>User Management</h4>
                <p>You do not have access to this page.  Please make another selection</p>
            </template>
        </pvCard>
        <pvCard v-if="isAdmin">
            <template #content>
                <pvDataTable :value="data" :loading="loading" ref="dt" class="p-datatable-sm" showGridlines >
                    <template #empty> No data found. </template>
                    <template #loading> Loading data. Please wait. </template>
                    <template #header>
                        <table style="width: 100%">
                            <tr>
                                <td>
                                    <label>Filter: </label>
                                    <pvDropdown v-model="filter" :options="filterList" placeholder="ACTIVE" v-on:change="getUserList" class="w-full md:w-14rem" />
                                </td>
                                <td><pvButton label="New User" v-on:click="newUser"></pvButton></td>
                            </tr>
                        </table>
                    </template>
                    <pvColumn field="USER_NAME" header="Username" class="dtCol" style="width: 100px; font-size: 12px;"></pvColumn>
                    <pvColumn field="USER_FNAME" header="First Name" class="dtCol" style="width: 100px; font-size: 12px;"></pvColumn>
                    <pvColumn field="USER_LNAME" header="Last Name" class="dtCol" style="width: 100px; font-size: 12px;"></pvColumn>
                    <pvColumn field="USER_EMAIL" header="Email" class="dtCol" style="width: 200px; font-size: 12px;"></pvColumn>
                    <pvColumn field="SEC_DESC" header="Security Level" class="dtCol" style="width: 50px; font-size: 12px;"></pvColumn>
                    <pvColumn field="AGENCY_NAME" header="Agency" class="dtCol" style="width: 50px; font-size: 12px;"></pvColumn>
                    <pvColumn field="Active" header="Active" class="dtCol" style="width: 50px; font-size: 12px;"></pvColumn>
                    <pvColumn header="Edit User" class="dtCol" style="width: 50px; font-size: 12px;">
                        <template #body="slotProps">
                            <pvButton icon="pi pi-pencil" outlined rounded class="mr-2" v-on:click="editUser(slotProps.data)"></pvButton>
                        </template>
                    </pvColumn>
                </pvDataTable>
            </template>
        </pvCard>
        <pvDialog v-model:visible="userDialog" modal header="User Management" style="width: 600px;">
            <pvCard style="width: 540px;">
                <template #content>
                    <table style="width: 100%">
                        <tr>
                            <td class="leftSide"><label>User Name</label></td>
                            <td><pvInputText type="text" v-model="uName" class="input"></pvInputText></td>
                        </tr>
                        <tr>
                            <td><label>First Name</label></td>
                            <td><pvInputText type="text" v-model="uFName" class="input"></pvInputText></td>
                        </tr>
                        <tr>
                            <td><label>Last Name</label></td>
                            <td><pvInputText type="text" v-model="uLName" class="input"></pvInputText></td>
                        </tr>
                        <tr>
                            <td><label>Email Address</label></td>
                            <td><pvInputText type="text" v-model="uEmail" class="input"></pvInputText></td>
                        </tr>
                        <tr>
                            <td><label>Security Level</label></td>
                            <td><pvDropdown v-model="uSecurity" :options="sLevel" class="input"></pvDropdown></td>
                        </tr>
                        <tr>
                            <td><label>Agency</label></td>
                            <td><pvDropdown v-model="uAgency" :options="agency" class="input"></pvDropdown></td>
                        </tr>
                        <tr>
                            <td><label>Active</label></td>
                            <td><pvToggleButton v-model="uActive"
                                    onLabel="Active" offLabel="Not Active"
                                    onIcon="pi pi-check" offIcon="pi pi-times"
                                    class="input">
                                </pvToggleButton></td>
                        </tr>
                        <tr v-if="uID != 'NEW'">
                            <td><label>Send Password Reset</label></td>
                            <td><pvButton label="Send Password Reset Request" v-on:click="sendPassword" class="input"></pvButton></td>
                        </tr>
                    </table>
                    <small v-if="uID === 'NEW'">When creating a new user, an email will be sent to the user with a temporary password.</small>
                    <small v-if="uID != 'NEW'">When resetting a password, an email will be sent to the user using the email address listed above.</small>
                </template>
            </pvCard>
            <template #footer>
                <pvButton label="Cancel" v-on:click="closeEdit"></pvButton>
                <pvButton label="Save" v-on:click="userSave"></pvButton>
            </template>
        </pvDialog>
        <pvDialog v-model:visible="errorDialog" modal header="Error Notification" style="width: 400px;">
            <pvCard style="width: 350px;">
                <template #content>
                    <h4>{{ errorText }}</h4>
                </template>
            </pvCard>
            <template #footer>
                <pvButton label="Close" v-on:click="closeError"></pvButton>
            </template>
        </pvDialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                data: [],
                filter: 'ACTIVE',
                filterList: ['ALL', 'ACTIVE', 'NOT ACTIVE'],
                loading: false,
                userDialog: false,
                sLevel: ['ABC', 'SRS', 'Greenbush', 'ABC Security Administrator', 'SRS Security Administrator', 'Web Administrator', 'ABC File Upload'],
                agency: ['ABC', 'SRS', 'GREENBUSH'],
                uID: '',
                uFName: '',
                uLName: '',
                uName: '',
                uEmail: '',
                uSecurity: '',
                uAgency: '',
                uActive: true,
                options: ['true', 'false'],
                errorDialog: false,
                errorText: '',
                isAdmin: false,
            }
        },
        computed: {
            isLoggedIn() {
                return this.$store.getters["isAuthenticated"];
            }
        },
        methods: {
            closeError() {
                this.errorDialog = false;
            },  
            closeEdit() {
                this.userDialog = false;
            },
            newUser() {
                this.clearDialog();

                this.uID = 'NEW';
                this.userDialog = true;

            },
            async userSave() {

                var isValid = true;
                var result = '';

                if(this.uName === '' || this.uName === null) {isValid = false;}
                if(this.uFName === '' || this.uFName === null) {isValid = false;}
                if(this.uLName === '' || this.uLName === null) {isValid = false;}
                if(this.uEmail === '' || this.uEmail === null) {isValid = false;}
                if(this.uSecurity === '' || this.uSecurity === null) {isValid = false;}
                if(this.uAgency === '' || this.uAgency === null) {isValid = false;}

                if(isValid === false) {
                    this.errorText = 'The form you are filling out is not complete, please complete the form before saving';
                    this.errorDialog = true;
                    return;
                }
                //console.log("validate email");
                //console.log(this.uEmail.split("@").length-1);
                //console.log(this.uEmail.split(".").length-1);
                if(this.uEmail.split("@").length-1 === 0 || this.uEmail.split(".").length-1 === 0) {
                    this.errorText = 'The email address entered is not formated correctly';
                    this.errorDialog = true;
                    return;
                }

                try {
                    const formData = {
                        uID: this.uID,
                        user: this.uName,
                        fName: this.uFName,
                        lName: this.uLName,
                        eMail: this.uEmail,
                        level: this.uSecurity,
                        agency: this.uAgency,
                        active: this.uActive
                    }

                    //console.log(formData);

                    await this.$store.dispatch('putUserSave', formData);   
                    result = this.$store.getters['getUserResponse'];
                    console.log(result[0].RESULT);
    

                } catch (error) {
                    console.log (this.error = error.message  + " Inside script" || "Something failed!");
                    this.isLoading = false;
                    this.errorText ='Unable to connect to the database','Connection Error';
                    this.errorDialog = true;
                }

                if(result[0].RESULT === 'DUPLICATE') {
                    this.errorText = 'The Username already exist. Either edit the existing user or create a new username';
                    this.errorDialog = true;
                    return;
                }

                if(this.uID === 'NEW') {
                    var mBody = 'Your KASBCCB account is now active with the username of ' + this.uName + '. You can access the site at https://ksabccb.net.  Your password will be sent to you in a seperate email';

                    const newMail = {
                        app: 'SYNAR',
                        recipient: this.uEmail,
                        cc: '',
                        bcc: '',
                        genDate: '',
                        isHTML: 'NO',
                        subject: 'Your KASBCCB Account',
                        body: mBody
                    }

                    //console.log(newMail);
                    await this.$store.dispatch('putSendMail', newMail);

                    this.sendPassword();
                }

                this.userDialog = false;
                this.getUserList();
            },
            async sendPassword() {
                var length1 = 3,
                charset1 = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
                retVal1 = "";
                for (var i1 = 0, n1 = charset1.length; i1 < length1; ++i1) {
                retVal1 += charset1.charAt(Math.floor(Math.random() * n1));
                }

                var length2 = 3,
                charset2 = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
                retVal2 = "";
                for (var i2 = 0, n2 = charset2.length; i2 < length2; ++i2) {
                retVal2 += charset2.charAt(Math.floor(Math.random() * n2));
                }

                var length3 = 3,
                charset3 = "abdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
                retVal3 = "";
                for (var i3 = 0, n3 = charset3.length; i3 < length3; ++i3) {
                retVal3 += charset3.charAt(Math.floor(Math.random() * n3));
                }


                this.pHashNew = retVal1 + retVal2 + retVal3;
                //console.log(this.pHashNew);

                this.newPWord = true;

                let PSENCODE = this.pHashNew;
                let passwordEncrypted = "";
                for (let i = 0; i < PSENCODE.length; i++) {
                    // Find the ASCII code for this character, add it to the 
                    // return string with a period
                    passwordEncrypted += PSENCODE.charCodeAt(i) + ".";
                }
                //console.log(passwordEncrypted);
                var response = ''
                try {
                    const formData = {
                        user: this.uName,
                        pass: passwordEncrypted
                    }
                    await this.$store.dispatch('putPassReset', formData);
                    response = this.$store.getters['getUserResponse'];

                    console.log(response);

                } catch (error) {
                    console.log (this.error = error.message  + " Inside script" || "Something failed!");
                    this.isLoading = false;
                    this.errorText ='Unable to connect to the database','Connection Error';
                    this.errorDialog = true;
                }

                var mBody = 'Your New temporary password for KASBCCB is ' + this.pHashNew + '. You will be required to change your password when you log in.';

                const newMail = {
                    app: 'SYNAR',
                    recipient: this.uEmail,
                    cc: '',
                    bcc: '',
                    genDate: '',
                    isHTML: 'NO',
                    subject: 'KASBCCB Access',
                    body: mBody
                }

                //console.log(newMail);
                await this.$store.dispatch('putSendMail', newMail);

                this.userDialog = false;

            },
            clearDialog() {
                this.uID = '';
                this.uName = '';
                this.uFName = '';
                this.uLName = '';
                this.uEmail = '';
                this.uSecurity = '';
                this.uAgency = '';
                this.uActive = true;
            },
            editUser(data) {
                console.log(data);
                this.uID = data.USER_ID;
                this.uName = data.USER_NAME;
                this.uFName = data.USER_FNAME;
                this.uLName = data.USER_LNAME;
                this.uEmail = data.USER_EMAIL;
                this.uSecurity = data.SEC_DESC;
                this.uAgency = data.AGENCY_NAME;

                if(data.Active === 'TRUE') {
                    this.uActive = true;
                } else {
                    this.uActive = false;
                }

                this.userDialog = true;

            
            },
            userValidation() {
                var uData = this.$store.getters['getUserValidation'];
                console.log(uData);
                if(uData[0].SEC_LVL === 5) {
                    this.isAdmin = true;
                } else {
                    this.isAdmin = false;
                }
            },
            async getUserList() {
                if(this.isLoggedIn === false) {
                    return;
                }
                try {
                    const formData = {
                        filter: this.filter
                    }
                    await this.$store.dispatch('getUserList', formData);
                    this.data = this.$store.getters['getUserList'];
                    this.loading = false;
                    console.log(this.data);
                } catch (error) {
                    console.log (this.error = error.message  + " Inside script" || "Something failed!");
                    this.isLoading = false;
                    this.errorText ='Unable to connect to the database','Connection Error';
                    this.errorDialog = true;
                }
            }
        },
        mounted() {
            this.userValidation();
            this.getUserList();
        }
    }
</script>

<style scoped>
    .dm{
        width: 320px;
    }
    dtCol {
        width: 100px;
        font-size: 12px;
    }
    .leftSide {
        width: 175px;
    }
    .input {
        width: 300px;
    }
</style>