<template>
    <div>
        <pvCard style="width: 600px; margin-left: auto; margin-right: auto;">
            <template #content>
                <h3>Inspection Tallies Eligible / Ineligible</h3>
                <br>
                <pvDataTable :value="data" :loading="loading" ref="dt" class="p-datatable-sm">
                    <template #empty> No found. </template>
                    <template #loading> Loading data. Please wait. </template>
                    <template #header>
                        <div>
                            <pvDropdown v-model="period" :options="yearList" optionLabel="YEAR_TEXT" optionValue="YEAR_TEXT" v-on:change="getReportData" class="dm"></pvDropdown>
                            <pvButton icon="pi pi-external-link" label="Export" v-on:click="gotoExport($event)" style="margin-left: 10px;"></pvButton> 
                        </div>    
                    </template>    
                    <pvColumn field="REASONS_TXT" header="REASON" style="width: 400px"></pvColumn>
                    <pvColumn field="INSPECT_CNT" header="COUNT" style="width: 100px"></pvColumn>
                </pvDataTable>
            </template>
        </pvCard>
        <pvDialog v-model:visible="errorDialog" modal header="Error Notification" style="width: 400px;">
            <pvCard style="width: 350px;">
                <template #content>
                    <h4>{{ errorText }}</h4>
                </template>
            </pvCard>
            <template #footer>
                <pvButton label="Close" v-on:click="closeError"></pvButton>
            </template>
        </pvDialog>
    </div>
</template>

<script>
import Papa from "papaparse";
export default {
    data() {
        return {
            data: [],
            yearList: [],
            loading: false,
            period: '',
            errorDialog: false,
            errorText: '',
        }
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters["isAuthenticated"];
        }
    },
    methods: {
        gotoExport() {
            //this.$refs.dt.exportCSV();

            var blob = new Blob([Papa.unparse(this.data)], { type: 'text/csv;charset=utf-8;' });

            var link = document.createElement("a");

            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", 'inspection_tallies.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        async getReportData() {
            if(this.isLoggedIn === false){
                return;
            }
            try {
                const formData = {
                    period: this.period
                }
                this.loading = true;
                await this.$store.dispatch('getReportEligible', formData);
                this.data = this.$store.getters['getEligible'];
                this.loading = false;
                //console.log(this.data);

            } catch (error) {
                console.log (this.error = error.message  + " Inside script" || "Something failed!");
                this.dLoading = false;
                this.errorText ='Unable to connect to the database','Connection Error';
                this.errorDialog = true;
            }
            
        },
        getYearList() {
            this.yearList = this.$store.getters['getYearList'];
            this.period = this.yearList[0].YEAR_TEXT;

            console.log(this.yearList);
        }
    },
    mounted() {
        this.getYearList();
        this.getReportData();
    }
}
</script>

<style scoped>
    .dm{
        width: 400px;
    }
</style>