import { createStore } from 'vuex'
import axios from "axios";

export default createStore({
    state: {
        //devCode: 'dev',
        //devCode: 'alpha',
        //devCode: 'beta',
        devCode: 'final',
        apiKey: 'GB609SekEsc',
        ipAddressD: 'http://10.46.0.49:3005',
        ipAddressA: 'https://synar-dev.greenbush.org',
        ipAddressB: 'https://synar-dev.greenbush.org',
        ipAddressF: 'https://ksabccb.net',
        userName: '',
        isLoggedIn: false,
        reportEligible: [],
        reportScorecard: [],
        reportScorecardDetail: [],
        synarScorecard: [],
        synarScorecardDetail: [],
        yearList: [],
        activeAgents: [],
        userValidate: '',
        userLevel: 0,
        userList: [],
        userResponse: [],
        activeLicenses: [],
        mSampleData: [],
        assignmentsList: [],
        assignmentsListCounty: [],
        assignmentSaveResponse: [],
    },
    getters: {
        getUserName(state) {
            return state.userName;
        },
        isAuthenticated(state) {
            return state.isLoggedIn;
        },
        getEligible(state) {
            return state.reportEligible;
        },
        getYearList(state) {
            return state.yearList;
        },
        getScorecard(state) {
            return state.reportScorecard;
        },
        getScorecardDetail(state) {
            return state.reportScorecardDetail;
        },
        getActiveAgents(state) {
            return state.activeAgents;
        },
        getUserValidation(state) {
            return state.userValidate;
        },
        getUserLevel(state) {
            return state.userLevel;
        },
        getUserList(state) {
            return state.userList;
        },
        getUserResponse(state) {
            return state.userResponse;
        },
        getSynarScorecard(state) {
            return state.synarScorecard;
        },
        getSynarScorecardDetail(state) {
            return state.synarScorecardDetail;
        },
        getActiveLicenses(state) {
            return state.activeLicenses;
        },
        getMSampleData(state) {
            return state.mSampleData;
        },
        getAssignmentsList(state) {
            return state.assignmentsList;
        },
        getAssignmentsListCounty(state) {
            return state.assignmentsListCounty;
        },
        getAssignmentSaveResponse(state) {
            return state.assignmentSaveResponse;
        }
    },
    mutations: {
        setUserName(state, payload) {
            state.userName = payload; 
        },
        login(state) {
            state.isLoggedIn = true;
        },
        logout(state) {
            state.isLoggedIn = false;
        },
        setEligible(state, payload) {
            state.reportEligible = payload;
        },
        setYearList(state, payload) {
            state.yearList = payload;
        },
        setScorecard(state, payload) {
            state.reportScorecard = payload;
        },
        setScorecardDetail(state, payload) {
            state.reportScorecardDetail = payload;
        },
        setActiveAgents(state, payload) {
            state.activeAgents = payload;
        },
        setUserValidation(state, payload) {
            state.userValidate = payload;
        },
        setUserLevel(state, payload) {
            state.userLevel = payload;
        },
        setUserList(state, payload) {
            state.userList = payload;
        },
        setUserResponse(state, payload) {
            state.userResponse = payload;
        },
        setSynarScorecard(state, payload) {
            state.synarScorecard = payload;
        },
        setSynarScorecardDetail(state, payload) {
            state.synarScorecardDetail = payload;
        },
        setActiveLicenses(state, payload) {
            state.activeLicenses = payload;
        },
        setMSampleData(state, payload) {
            state.mSampleData = payload;
        },
        setAssignmentsList(state, payload) {
            state.assignmentsList = payload;
        },
        setAssignmentsListCounty(state, payload) {
            state.assignmentsListCounty = payload;
        },
        setAssignmentSaveResponse(state, payload) {
            state.assignmentSaveResponse = payload;
        },
    },
    actions: {
        putUserName(context, data) {
            context.commit('setUserName', data)
        },
        putUserLevel(context, data) {
            context.commit('setUserLevel', data)
        },
        login (context, data) {
            context.commit('login');
            context.commit('setUserName', data);

        },
        logout (context) {
            context.commit('logout')
            context.commit('setUserName', '');
        },
        async getReportEligible(context, data) {
            const apiKey = context.rootState.apiKey;
            var ip;
            if(context.rootState.devCode === 'dev') {
                ip = context.rootState.ipAddressD;
            }
            if(context.rootState.devCode === 'alpha') {
                ip = context.rootState.ipAddressA;
            }
            if(context.rootState.devCode === 'beta') {
                ip = context.rootState.ipAddressB;
            }
            if(context.rootState.devCode === 'final') {
                ip = context.rootState.ipAddressF;
            }

            const response = await axios.get(ip +'/api/synar/getReportEligible', {
                headers: {'apiKey': apiKey},
                params: {period: data.period}
            });
            const ListData = await response.data;

            //console.log(ListData);
            context.commit('setEligible', ListData);
        },
        async getYearList(context) {
            const apiKey = context.rootState.apiKey;
            var ip;
            if(context.rootState.devCode === 'dev') {
                ip = context.rootState.ipAddressD;
            }
            if(context.rootState.devCode === 'alpha') {
                ip = context.rootState.ipAddressA;
            }
            if(context.rootState.devCode === 'beta') {
                ip = context.rootState.ipAddressB;
            }
            if(context.rootState.devCode === 'final') {
                ip = context.rootState.ipAddressF;
            }

            const response = await axios.get(ip +'/api/synar/getYearList', {
                headers: {'apiKey': apiKey}
            });
            const ListData = await response.data;

            //console.log(ListData);
            context.commit('setYearList', ListData);
        },

        async getScorecard(context, data) {
            const apiKey = context.rootState.apiKey;
            var ip;
            if(context.rootState.devCode === 'dev') {
                ip = context.rootState.ipAddressD;
            }
            if(context.rootState.devCode === 'alpha') {
                ip = context.rootState.ipAddressA;
            }
            if(context.rootState.devCode === 'beta') {
                ip = context.rootState.ipAddressB;
            }
            if(context.rootState.devCode === 'final') {
                ip = context.rootState.ipAddressF;
            }

            const response = await axios.get(ip +'/api/synar/getCCBScorecard', {
                headers: {'apiKey': apiKey},
                params: {
                    by: data.by,
                    dtStart: data.dtStart,
                    dtStop: data.dtStop
                }
            });
            const ListData = await response.data;

            //console.log(ListData);
            context.commit('setScorecard', ListData);
        },
        async getActiveAgents(context) {
            const apiKey = context.rootState.apiKey;
            var ip;
            if(context.rootState.devCode === 'dev') {
                ip = context.rootState.ipAddressD;
            }
            if(context.rootState.devCode === 'alpha') {
                ip = context.rootState.ipAddressA;
            }
            if(context.rootState.devCode === 'beta') {
                ip = context.rootState.ipAddressB;
            }
            if(context.rootState.devCode === 'final') {
                ip = context.rootState.ipAddressF;
            }

            const response = await axios.get(ip +'/api/synar/getActiveAgents', {
                headers: {'apiKey': apiKey}
            });
            const ListData = await response.data;

            //console.log(ListData);
            context.commit('setActiveAgents', ListData);
        },
        async getuser(context, data) {
            const apiKey = context.rootState.apiKey;
            var ip;
            if(context.rootState.devCode === 'dev') {
                ip = context.rootState.ipAddressD;
            }
            if(context.rootState.devCode === 'alpha') {
                ip = context.rootState.ipAddressA;
            }
            if(context.rootState.devCode === 'beta') {
                ip = context.rootState.ipAddressB;
            }
            if(context.rootState.devCode === 'final') {
                ip = context.rootState.ipAddressF;
            }

            //const formData = {
                //user: data.user
            //}
            //console.log(data);
            //console.log(formData);
            const response = await axios.get(ip +'/api/synar/getUser', {
                headers: {'apiKey': apiKey},
                params: {user: data.user}
            });
            const ListData = await response.data;

            //console.log(ListData);
            context.commit('setUserValidation', ListData);
        },
        async putNewPass(context, data) {
            const apiKey = context.rootState.apiKey;
            var ip;
            if(context.rootState.devCode === 'dev') {
                ip = context.rootState.ipAddressD;
            }
            if(context.rootState.devCode === 'alpha') {
                ip = context.rootState.ipAddressA;
            }
            if(context.rootState.devCode === 'beta') {
                ip = context.rootState.ipAddressB;
            }
            if(context.rootState.devCode === 'final') {
                ip = context.rootState.ipAddressF;
            }

            const formData = {
                user: data.user,
                pass: data.pass,
            }

            const response = await axios.post(ip + '/api/synar/putNewPass', formData, {
                headers: {'apiKey': apiKey}
              });
        
              const responseData = await response.data;
        
              if (response.statusText != ""  && response.statusText !="OK") {
                console.log("Action Error")
                const error = new Error(responseData.message + " Inside Vuex"|| "Failed to fetch request.");
                throw error;
              }
        },
        async getUserList(context, data) {
            const apiKey = context.rootState.apiKey;
            var ip;
            if(context.rootState.devCode === 'dev') {
                ip = context.rootState.ipAddressD;
            }
            if(context.rootState.devCode === 'alpha') {
                ip = context.rootState.ipAddressA;
            }
            if(context.rootState.devCode === 'beta') {
                ip = context.rootState.ipAddressB;
            }
            if(context.rootState.devCode === 'final') {
                ip = context.rootState.ipAddressF;
            }

            //const formData = {
                //filter: data.filter
            //}
            //console.log(data);
            //console.log(formData);
            const response = await axios.get(ip +'/api/synar/getUserList', {
                headers: {'apiKey': apiKey},
                params: {filter: data.filter}
            });
            const ListData = await response.data;

            //console.log(ListData);
            context.commit('setUserList', ListData);
        },

        async putUserSave(context, data) {
            const apiKey = context.rootState.apiKey;
            var ip;
            if(context.rootState.devCode === 'dev') {
                ip = context.rootState.ipAddressD;
            }
            if(context.rootState.devCode === 'alpha') {
                ip = context.rootState.ipAddressA;
            }
            if(context.rootState.devCode === 'beta') {
                ip = context.rootState.ipAddressB;
            }
            if(context.rootState.devCode === 'final') {
                ip = context.rootState.ipAddressF;
            }

            const formData = {
                uID: data.uID,
                user: data.user,
                fName: data.fName,
                lName: data.lName,
                eMail: data.eMail,
                level: data.level,
                agency: data.agency,
                active: data.active
            }

            const response = await axios.post(ip + '/api/synar/putUserSave', formData, {
                headers: {'apiKey': apiKey}
            });
        
            const responseData = await response.data;
        
            if (response.statusText != ""  && response.statusText !="OK") {
                console.log("Action Error")
                const error = new Error(responseData.message + " Inside Vuex"|| "Failed to fetch request.");
                throw error;
            }

            const ListData = await response.data;

            //console.log(ListData);
            context.commit('setUserResponse', ListData);
        },

        async putPassReset(context, data) {
            const apiKey = context.rootState.apiKey;
            var ip;
            if(context.rootState.devCode === 'dev') {
                ip = context.rootState.ipAddressD;
            }
            if(context.rootState.devCode === 'alpha') {
                ip = context.rootState.ipAddressA;
            }
            if(context.rootState.devCode === 'beta') {
                ip = context.rootState.ipAddressB;
            }
            if(context.rootState.devCode === 'final') {
                ip = context.rootState.ipAddressF;
            }

            const formData = {
                user: data.user,
                pass: data.pass,
            }

            const response = await axios.post(ip + '/api/synar/putPassReset', formData, {
                headers: {'apiKey': apiKey}
            });
    
            const responseData = await response.data;
    
            if (response.statusText != ""  && response.statusText !="OK") {
                console.log("Action Error")
                const error = new Error(responseData.message + " Inside Vuex"|| "Failed to fetch request.");
                throw error;
            }

            const ListData = await response.data;

            //console.log(ListData);
            context.commit('setUserResponse', ListData);
        },

        async putSendMail(context, data) {
            const apiKey = context.rootState.apiKey;
            
            const formData = {
                app: data.app,
                recipient: data.recipient,
                cc: data.cc,
                bcc: data.bcc,
                genDate: data.genDate,
                isHTML: data.isHTML,
                subject: data.subject,
                body: data.body,
            }

            const response = await axios.post('https://dataapi.greenbush.org/api/postSendMail', formData, {
                headers: {'apiKey': apiKey}
            });
    
            const responseData = await response.data;
    
            if (response.statusText != ""  && response.statusText !="OK") {
                console.log("Action Error")
                const error = new Error(responseData.message + " Inside Vuex"|| "Failed to fetch request.");
                throw error;
            }
        },

        async getScorecardDetail(context, data) {
            const apiKey = context.rootState.apiKey;
            var ip;
            if(context.rootState.devCode === 'dev') {
                ip = context.rootState.ipAddressD;
            }
            if(context.rootState.devCode === 'alpha') {
                ip = context.rootState.ipAddressA;
            }
            if(context.rootState.devCode === 'beta') {
                ip = context.rootState.ipAddressB;
            }
            if(context.rootState.devCode === 'final') {
                ip = context.rootState.ipAddressF;
            }

            const response = await axios.get(ip +'/api/synar/getCCBScorecardDetail', {
                headers: {'apiKey': apiKey},
                params: {
                    by: data.by,
                    dtStart: data.dtStart,
                    dtStop: data.dtStop,
                    county: data.county
                }
            });
            const ListData = await response.data;

            //console.log(ListData);
            context.commit('setScorecardDetail', ListData);
        },

        async getSynarScorecard(context, data) {
            const apiKey = context.rootState.apiKey;
            var ip;
            if(context.rootState.devCode === 'dev') {
                ip = context.rootState.ipAddressD;
            }
            if(context.rootState.devCode === 'alpha') {
                ip = context.rootState.ipAddressA;
            }
            if(context.rootState.devCode === 'beta') {
                ip = context.rootState.ipAddressB;
            }
            if(context.rootState.devCode === 'final') {
                ip = context.rootState.ipAddressF;
            }

            const response = await axios.get(ip +'/api/synar/getSynarScorecard', {
                headers: {'apiKey': apiKey},
                params: {
                    period: data.period
                }
            });
            const ListData = await response.data;

            //console.log(ListData);
            context.commit('setSynarScorecard', ListData);
        },

        async getSynarScorecardDetail(context, data) {
            const apiKey = context.rootState.apiKey;
            var ip;
            if(context.rootState.devCode === 'dev') {
                ip = context.rootState.ipAddressD;
            }
            if(context.rootState.devCode === 'alpha') {
                ip = context.rootState.ipAddressA;
            }
            if(context.rootState.devCode === 'beta') {
                ip = context.rootState.ipAddressB;
            }
            if(context.rootState.devCode === 'final') {
                ip = context.rootState.ipAddressF;
            }

            const response = await axios.get(ip +'/api/synar/getSynarScorecardDetail', {
                headers: {'apiKey': apiKey},
                params: {
                    period: data.period,
                    county: data.county
                }
            });
            const ListData = await response.data;

            //console.log(ListData);
            context.commit('setSynarScorecardDetail', ListData);
        },

        async getLicensesCurrentActive(context) {
            const apiKey = context.rootState.apiKey;
            var ip;
            if(context.rootState.devCode === 'dev') {
                ip = context.rootState.ipAddressD;
            }
            if(context.rootState.devCode === 'alpha') {
                ip = context.rootState.ipAddressA;
            }
            if(context.rootState.devCode === 'beta') {
                ip = context.rootState.ipAddressB;
            }
            if(context.rootState.devCode === 'final') {
                ip = context.rootState.ipAddressF;
            }

            const response = await axios.get(ip +'/api/synar/getLicensesCurrentActive', {
                headers: {'apiKey': apiKey}
            });
            const ListData = await response.data;

            //console.log(ListData);
            context.commit('setActiveLicenses', ListData);
        },

        async putImportSample(context, data) {
            const apiKey = context.rootState.apiKey;
            var ip;
            if(context.rootState.devCode === 'dev') {
                ip = context.rootState.ipAddressD;
            }
            if(context.rootState.devCode === 'alpha') {
                ip = context.rootState.ipAddressA;
            }
            if(context.rootState.devCode === 'beta') {
                ip = context.rootState.ipAddressB;
            }
            if(context.rootState.devCode === 'final') {
                ip = context.rootState.ipAddressF;
            }

            const formData = {
                data: data,
            }
            console.log(formData);
            const response = await axios.post(ip + '/api/synar/putImportSample', formData, {
                headers: {'apiKey': apiKey}
            });
        
            const responseData = await response.data;
        
            if (response.statusText != ""  && response.statusText !="OK") {
                console.log("Action Error")
                const error = new Error(responseData.message + " Inside Vuex"|| "Failed to fetch request.");
                throw error;
            }

            const ListData = await response.data;

            console.log(ListData);
            context.commit('setMSampleData', ListData);
        },

        async pullAssignmentsList(context, data) {
            const apiKey = context.rootState.apiKey;
            var ip;
            if(context.rootState.devCode === 'dev') {
                ip = context.rootState.ipAddressD;
            }
            if(context.rootState.devCode === 'alpha') {
                ip = context.rootState.ipAddressA;
            }
            if(context.rootState.devCode === 'beta') {
                ip = context.rootState.ipAddressB;
            }
            if(context.rootState.devCode === 'final') {
                ip = context.rootState.ipAddressF;
            }
            console.log(data);
            const response = await axios.get(ip +'/api/synar/getAssignmentsList', {
                headers: {'apiKey': apiKey},
                params: {
                    year: data.year,
                    type: data.type
                }
            });
            const ListData = await response.data;

            //console.log(ListData);
            context.commit('setAssignmentsList', ListData);
        },

        async pullAssignmentsListCounty(context, data) {
            const apiKey = context.rootState.apiKey;
            var ip;
            if(context.rootState.devCode === 'dev') {
                ip = context.rootState.ipAddressD;
            }
            if(context.rootState.devCode === 'alpha') {
                ip = context.rootState.ipAddressA;
            }
            if(context.rootState.devCode === 'beta') {
                ip = context.rootState.ipAddressB;
            }
            if(context.rootState.devCode === 'final') {
                ip = context.rootState.ipAddressF;
            }
            console.log(data);
            const response = await axios.get(ip +'/api/synar/getAssignmentsListCounty', {
                headers: {'apiKey': apiKey},
                params: {
                    county: data
                }
            });
            const ListData = await response.data;

            //console.log(ListData);
            context.commit('setAssignmentsListCounty', ListData);
        },

        async putAssignmentsSave(context, data) {
            const apiKey = context.rootState.apiKey;
            var ip;
            if(context.rootState.devCode === 'dev') {
                ip = context.rootState.ipAddressD;
            }
            if(context.rootState.devCode === 'alpha') {
                ip = context.rootState.ipAddressA;
            }
            if(context.rootState.devCode === 'beta') {
                ip = context.rootState.ipAddressB;
            }
            if(context.rootState.devCode === 'final') {
                ip = context.rootState.ipAddressF;
            }

            const formData = {
                assignID: data.assignID,
                agentID: data.agentID,
            }

            const response = await axios.post(ip + '/api/synar/putAssignmentsSave', formData, {
                headers: {'apiKey': apiKey}
            });
    
            const responseData = await response.data;
    
            if (response.statusText != ""  && response.statusText !="OK") {
                console.log("Action Error")
                const error = new Error(responseData.message + " Inside Vuex"|| "Failed to fetch request.");
                throw error;
            }

            const ListData = await response.data;

            console.log(ListData);
            context.commit('setAssignmentSaveResponse', ListData);
        },
    },
    modules: {
    }
})
