<template>
    <div>
        <pvCard>
            <template #content>
                <h3>WELCOME TO THE ABC CONTROLLED BUY WEBSITE:</h3>
                <h5>Please Login</h5>
                <div style="ali: center;">
                    <table style="width: 100px">
                        <tr v-if="userName.isValid === false">
                            <td></td> 
                            <td><small style="color: red; ">User Name is Invalid</small></td>
                        </tr>
                        <tr>
                            <td>Username</td>
                            <td><pvInputText type="text" v-model="userName.val" v-on:keypress="clearUserError"></pvInputText></td>
                        </tr>
                        <tr v-if="password.isValid === false">
                            <td></td> 
                            <td><small style="color: red; ">Password is Invalid</small></td>
                        </tr>
                        <tr>
                            <td>Password</td>
                            <td><pvPassword v-model="password.val" :feedback="false" v-on:keypress="clearPassError" toggleMask ></pvPassword></td>
                        </tr>
                        <tr v-if="!validAccount">
                            <td></td>
                            <td><small style="color: red; ">Account not Valid or Active</small></td>
                        </tr>
                    </table>
                </div>
                <br>
                <pv-Button label="Login" v-on:click="login" ></pv-Button>
                <pv-Button label="Clear" v-on:click="clearFields"></pv-Button>
                <pv-Button label="Forgot Password" v-on:click="forgotPassword" ></pv-Button>
            </template>
        </pvCard>
        <pvDialog v-model:visible="updateDialog" modal header="Update Your Password" style="width: 600px;">
            <pvCard style="width: 540px">
                <template #content>
                    <table style="width: 100%">
                        <tr>
                            <td style="text-align: center;">
                                <h4 style="margin-bottom: 5px; ">Your password has expired, Please enter a New Password</h4><br>
                                <small style="margin-top: 5px; ">Password must be at least 12 characters and include letters, numbers, and special characters (!,@,#,$,%,&,*).</small><br>
                            </td>
                        </tr>
                    </table>
                    <table style="width: 100px">
                        <tr>
                            <td>Password</td>
                            <td><pvPassword v-model="password1.val" :feedback="true" 
                                strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!,@,#,$,%,&,*])(?=.{12,})"
                                toggleMask ></pvPassword></td>
                        </tr>
                        <tr>
                            <td>Confirm</td>
                            <td><pvPassword v-model="password2.val" :feedback="true" 
                                strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!,@,#,$,%,&,*])(?=.{12,})"
                                toggleMask ></pvPassword></td>
                        </tr>
                    </table>
                    <table style="width: 100%">
                        <tr>
                            <td style="text-align: center;">
                                <small v-if="!passMatch" id="status-help" class="p-error">Passwords do not match.</small><br>
                                <small v-if="!isValidNew" id="password-requirements" class="p-error">Password does not meet minimum requirements.</small>
                            </td>
                        </tr>
                    </table>
                </template>
            </pvCard>
            <template #footer>
                <pvButton label="Clear" v-on:click="clearNewPass"></pvButton>
                <pvButton label="Continue" v-on:click="saveNewPass"></pvButton>
            </template>
        </pvDialog>
        <pvDialog v-model:visible="forgotDialog" modal header="Forgot Password" style="width: 600px;">
            <pvCard>
                <template #content>
                    <h4>Please Enter your Email Address. If your email address is valid, you will recieve a new temporary password.</h4>
                    <table style="width: 100%">
                        <tr>
                            <td>Enter your User Name</td>
                            <td><pvInputText type="text" v-model="userName.val" class="input"></pvInputText></td>
                        </tr>
                        <tr>
                            <td>Enter your Email Address</td>
                            <td><pvInputText type="text" v-model="uEmail" class="input"></pvInputText></td>
                        </tr>
                    </table>
                    <small v-if="!forgotValid" style="color: red; ">User Name or Email is missing. Please complete before sending request.</small>
                </template>
            </pvCard>
            <template #footer>
                <pvButton label="Clear" v-on:click="clearForgot"></pvButton>
                <pvButton label="Send Request" v-on:click="saveForgot"></pvButton>
            </template>
        </pvDialog>
        <pvDialog v-model:visible="errorDialog" modal header="Error Notification" style="width: 400px;">
            <pvCard style="width: 350px;">
                <template #content>
                    <h4>{{ errorText }}</h4>
                </template>
            </pvCard>
            <template #footer>
                <pvButton label="Close" v-on:click="closeError"></pvButton>
            </template>
        </pvDialog>
    </div>
</template>

<script>
import CryptoJS from 'crypto-js'

export default {
    data() {
        return {
            //userName: {val: 'stonedart@hotmail.com', isValid: true},
            //password: {val: '2BorNot2B', isValid: true},
            userName: {val: '', isValid: true},
            password: {val: '', isValid: true},
            isValid: true,
            isValidLogin: false,
            district: '',
            year: '',
            enabled: '',
            enabledDialog: false,
            userValidate: '',
            updateDialog: false,
            isValidNew: true,
            code: {val: '', isValid: true},
            password1: {val: '', isValid: true},
            password2: {val: '', isValid: true},
            passMatch: true,
            pSave: '',
            salt1: 'SekEsc609',
            validAccount: true,
            forgotDialog: false,
            uEmail: '',
            forgotValid: true,
            errorDialog: false,
            errorText: ''

        }
    },
    methods: {
        closeError() {
            this.errorDialog = false;
        },
        clearForgot() {
            this.uEmail = '';
            this.userName.val = '';
        },
        clearFields() {
            this.userName.val = '';
            this.password.val = '';

            this.userName.isValid = true;
            this.password.isValid = true;

            this.isValid = true;
            this.isValidLogin = false;
        },
        clearUserError() {
            this.userName.isValid = true;
        },
        clearPassError() {
            this.password.isValid = true;
        },
        clearNewPass() {
            this.code.val = '',
            this.password1.val = '',
            this.password2.val = '',

            this.code.isValid = true,
            this.password1.isValid = true,
            this.password2.isValid = true
        },
        forgotPassword() {
            this.userName.val = '';
            this.uEmail = '';
            this.forgotDialog = true;
        },
        async saveForgot() {
            var fpValid = true;
            if(this.userName.val === '') {fpValid = false;}
            if(this.uEmail === '') {fpValid = false;}
            if(fpValid === false){
                this.forgotValid = false;
                return;
            }
            
            try {
                const formData = {
                    user: this.userName.val
                }

                await this.$store.dispatch('getuser', formData);
                this.userValidate = this.$store.getters['getUserValidation']

                //console.log("response");
                //console.log(this.userValidate);
                //console.log(this.userValidate.length);

                if(this.userValidate[0].RESULT != 'NOT VALID USER') {
                    if(this.uEmail === this.userValidate[0].USER_EMAIL) {
                        console.log('sending password request');
                        this.sendPassword();
                        
                    }
                }

                this.userName.val = ''
                this.password.val = ''
                //this.uEmail = ''
                this.forgotDialog = false;
                

            } catch (error) {
                console.log (this.error = error.message  + " Inside script" || "Something failed!");
                this.isLoading = false;
                this.errorText ='Unable to connect to the database','Connection Error';
                this.errorDialog = true;
            }
            

        },
        async saveNewPass() {
            this.isValidNew = true;
            if(this.password1.val === '') {
                this.password1.isValid = false;
                this.isValidNew = false;
            } else {
                this.password1.isValid = true;
            }
            if(this.password1.val != this.password2.val) {
                this.passMatch = false;
                //this.isValidNew = false;
            } else {
                this.passMatch = true;
            }
            var lLetter = /[a-z]/;
            var uLetter = /[A-Z]/;
            var number = /[0-9]/;
            var char = /[!,@,#,$,%,&,*]/;
            if (this.password1.val.length < 12 || !uLetter.test(this.password1.val)|| !lLetter.test(this.password1.val) || !number.test(this.password1.val) || !char.test(this.password1.val)) {
                this.password1.isValid = false;
                this.isValidNew = false;
            }
            if(this.isValidNew === false || this.passMatch === false) {
                return;
            }
            //console.log("it works");

            this.pSave = CryptoJS.AES.encrypt(this.password1.val, this.salt1).toString()
            //console.log(this.pSave);
            //console.log("save");

            const formData = {
                user: this.userName.val,
                pass: this.pSave   
            }
            await this.$store.dispatch('putNewPass', formData);

            this.clearNewPass()
            this.password.val = '';

            this.updateDialog = false;
        }, 
        async login() {
            if(this.userName.val != '') {

                try {
                    const formData = {
                        user: this.userName.val
                    }
                    //console.log(formData);
                    await this.$store.dispatch('getuser', formData);
                    this.userValidate = this.$store.getters['getUserValidation']

                    //console.log("response");
                    //console.log(this.userValidate);


                    if(this.userValidate[0].RESULT === 'NOT VALID USER') {
                        this.userName.isValid = false;
                        return;
                    }

                    if(this.userValidate[0].RESULT === 'UPDATE') {
                        let PSENCODE = this.userValidate[0].PASS
                        let itCounter = PSENCODE.length;

                        //console.log(itCounter);
                        let tempText = ''

                        while (itCounter > 1) {
                            //'convert the current ASCII code to plain text
                            tempText += String.fromCharCode(parseInt(PSENCODE.slice(0, PSENCODE.indexOf("."))));
                            //'Trim the converted ASCII code off the code string so that we
                            //'can convert the next code number
                            PSENCODE = PSENCODE.slice(PSENCODE.indexOf(".") + 1);
                            itCounter = PSENCODE.length;
                        }
                    
                        //console.log(tempText);

                        if(this.password.val != tempText) {
                            this.password.isValid = false;
                            return;
                        }

                        this.updateDialog = true;

                        return
                    }

                    if(this.userValidate[0].RESULT != 'NOT VALID USER' && this.userValidate[0].RESULT != 'UPDATE') {
                        const pw = CryptoJS.AES.decrypt(this.userValidate[0].RESULT, this.salt1).toString(CryptoJS.enc.Utf8);
                        //console.log(pw);
                        if(pw != this.password.val) {
                            this.password.isValid = false;
                            return;
                        } else {
                            //console.log("success");

                            this.$store.dispatch('login', this.userName.val);
                            this.$store.dispatch('putUserLevel', this.userValidate[0].RESULT);
                            this.$router.push("home");
                            return;
                        }

                    }


                } catch (error) {
                    console.log (this.error = error.message  + " Inside script" || "Something failed!");
                    this.isLoading = false;
                    this.errorText ='Unable to connect to the database','Connection Error';
                    this.errorDialog = true;
                }
            }
        },
        async sendPassword() {
                var length1 = 3,
                charset1 = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
                retVal1 = "";
                for (var i1 = 0, n1 = charset1.length; i1 < length1; ++i1) {
                retVal1 += charset1.charAt(Math.floor(Math.random() * n1));
                }

                var length2 = 3,
                charset2 = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
                retVal2 = "";
                for (var i2 = 0, n2 = charset2.length; i2 < length2; ++i2) {
                retVal2 += charset2.charAt(Math.floor(Math.random() * n2));
                }

                var length3 = 3,
                charset3 = "abdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
                retVal3 = "";
                for (var i3 = 0, n3 = charset3.length; i3 < length3; ++i3) {
                retVal3 += charset3.charAt(Math.floor(Math.random() * n3));
                }


                this.pHashNew = retVal1 + retVal2 + retVal3;
                //console.log(this.pHashNew);

                this.newPWord = true;

                let PSENCODE = this.pHashNew;
                let passwordEncrypted = "";
                for (let i = 0; i < PSENCODE.length; i++) {
                    // Find the ASCII code for this character, add it to the 
                    // return string with a period
                    passwordEncrypted += PSENCODE.charCodeAt(i) + ".";
                }
                //console.log(passwordEncrypted);
                var response = ''
                try {
                    const formData = {
                        user: this.userName.val,
                        pass: passwordEncrypted
                    }
                    await this.$store.dispatch('putPassReset', formData);
                    response = this.$store.getters['getUserResponse'];

                    console.log(response);

                } catch (error) {
                    console.log (this.error = error.message  + " Inside script" || "Something failed!");
                    this.isLoading = false;
                    this.errorText ='Unable to connect to the database','Connection Error';
                    this.errorDialog = true;
                }

                var mBody = 'Your New temporary password for KASBCCB is ' + this.pHashNew + '. You will be required to change your password when you log in.';

                const newMail = {
                    app: 'SYNAR',
                    recipient: this.uEmail,
                    cc: '',
                    bcc: '',
                    genDate: '',
                    isHTML: 'NO',
                    subject: 'KASBCCB Access',
                    body: mBody
                }

                //console.log(newMail);
                await this.$store.dispatch('putSendMail', newMail);

                this.userDialog = false;

            },
    },
    mounted() {

    }
}
</script>

<style scoped>
    Button{
        margin-left: 5px;
    }
    table {
        width: 200px;
        margin-left: auto;
        margin-right: auto;
    }
</style>